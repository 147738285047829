<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'journal-refuse-list' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Прекратить учёт члена РСМ</h1>
    </div>
    <form class="form" @submit.prevent="onCheckForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="number">Номер билета<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.number.$error }">
              <input
                id="number"
                type="number"
                min="0"
                class="form-control__input"
                name="number"
                placeholder="Введите номер членского билета"
                v-model.number="form.number"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.number.$dirty && !$v.form.number.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label>Причина<sup>*</sup></label>
            <v-select
              v-model="form.reason"
              :reduce="reason => reason.id"
              :searchable="false"
              :clearable="false"
              :options="reason_options"
              :get-option-label="getLabel"
              placeholder="Выберите причину"
              class="select"
              :class="{'select--error': $v.form.reason.$error}"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.reason.$dirty && !$v.form.reason.required">
              Обязательное поле
            </div>
          </div>
        </div>
        <div class="form__part">
          <div v-if="form.reason === 1 || form.reason === 2" class="form-group">
            <label>Скан-копия заявления / протокола<sup>*</sup></label>
            <uploader :getFileHash="getFileHash" limit-files="1" :fileUrl="file_url" @remove-file="onRemoveFile" />
            <div class="form-group__error" v-if="$v.form.file.$dirty && !$v.form.file.required">Обязательное поле</div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" :class="{ 'preload': loading }" :disabled="$v.form.$error">
          <span>Прекратить учёт</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import Uploader from '@/components/Uploader'

export default {
  name: 'JournalRefuseCreate',
  mixins: [ validationMixin ],
  components: {
    Uploader,
  },
  validations: {
    form: {
      number: { required },
      reason: { required },
      file: {
        required: requiredIf(function(){
          return this.form.reason === 1 || this.form.reason === 2
        })
      },
    },
  },
  data() {
    return {
      form: {
        file: '',
      },
      file_url: `${process.env.VUE_APP_API_URL}files/`,
      loading: false,
      reason_options: [],
    }
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    this.form.number = this.$route.query.id ?? null
    if (this.userRole === 'admin') {
      this.$store.dispatch('reason/GET_REFUSE_LIST')
        .then(response => {this.reason_options = response.data.results})
    } else {
      this.$store.dispatch('reason/GET_REFUSE_LIST')
        .then(response => {this.reason_options = response.data})
    }
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      this.$store.dispatch('journal/POST_REFUSE_DATA', this.form)
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'journal-refuse-list' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Добавлено.'
          });
        })
        .catch(error => {
          this.loading = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    getFileHash(value) {
      this.form.file = value
    },
    onRemoveFile() {
      this.form.file = ''
    },
  }
}
</script>
